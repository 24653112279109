
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { Form, Field } from "vee-validate";
import { object, string } from "yup";
import { hideModal } from "@/core/helpers/dom";
import Quill from "quill/dist/quill.js";
import membersApi from "@/core/services/MembersApi";
import chapitresApi from "@/core/services/ChapitresApi";
import moment from "moment";
import axios from "axios";

moment.locale("fr");

export default defineComponent({
    name: "ChapitreDetails",
    components: { Form, Field },
    data() {
        return {
            editing: false,
            editingParticipant: false,
            intoParticipants: false,
            currentPage: 1,
            totalPages: 0,
            selectedParticipants: [] as any,
            loadedParticipants: [] as any,
            allParticipants: [] as any,
            preParticipants: [] as any,
            postParticipants: [] as any,
            actualFilter: "",
            actualCol: "",
            progressDialog: false,
            percentage: 0,
            participantsTh: [
                { label: "ID", class: "min-w-60px", colName: "id" },
                { label: "Name", class: "min-w-100px", colName: "lastname" },
                {
                    label: "Current Grade",
                    class: "min-w-175px",
                    colName: "current_grade",
                },
                {
                    label: "Selected Grade",
                    class: "min-w-175px",
                    colName: "selected_grade",
                },
                { label: "OMGD", class: "min-w-60px", colName: "omgd" },
                { label: "Medal", class: "min-w-60px", colName: "medal" },
                {
                    label: "Commandeur",
                    class: "min-w-60px",
                    colName: "commander",
                },
                {
                    label: "Bailliage",
                    class: "min-w-60px",
                    colName: "bailliage",
                },
            ],

            chapitre: {
                id: "",
                type: {
                    id: "",
                    label: "",
                },
                national_bailliage: {
                    id: "",
                    label_fr: "",
                    particle_fr: "",
                } as any,
                master: {
                    id: "",
                    code: "",
                    userprofile: {
                        id: "",
                        firstname: "",
                        lastname: "",
                    },
                },
                start_date: "",
                end_date: "",
                ceremony_date: "",
                code: 0,
                is_locked: false,
                is_pouvoir: false,
                is_livre_dor: false,
                is_published_online: false,
                title_fr: "",
                title_en: "",
                location_fr: "",
                location_en: "",
                description_fr: "",
                description_en: "",
                is_description_auto: false,
                participants: [] as any,
            } as any,
            chapitreBD: "",

            participantsTempList: [] as any,

            participant: {
                member: "",
                promotion: "",
                is_omgd_induction: "" as any,
                commander: "",
                medal: "",
            } as any,

            tempParticipant: {
                code: "",
                firstname: "",
                lastname: "",
                grade: "",
                induction: "",
                is_participating: false,
                payload: {
                    member: "",
                    promotion: "",
                    is_omgd_induction: "",
                    commander: "",
                    medal: "",
                },
            },

            intronisable: [
                {
                    id: "",
                    label: "No",
                    value: false,
                },
            ],

            commandeurs: [
                {
                    id: "",
                    label: "None",
                },
            ],

            medals: [
                {
                    id: "",
                    label: "None",
                },
            ],

            grades: [
                {
                    id: "",
                    label: "None",
                },
            ],

            chapitreTypes: [] as any,

            livre_dorTypes: [
                {
                    id: 0,
                    label: "Yes",
                    value: true,
                },
                {
                    id: 1,
                    label: "None",
                    value: false,
                },
            ],

            nBailliages: [] as any,
            remoteMembers: [] as any,
            chapitreMasterId: "",
            chapitreMaster: "",
            requestedPromotion: "",
            participantName: "",
            isOnline: false,
            loading: true,
            title: "" as any,
            frEditor: "" as any,
            enEditor: "" as any,
            participantSelected: false,
            disableParticipantName: false,
            participantId: "",
            participantMemberId: "",
            participantSearch: "",
            perm_pos: "",

            files_livre_dor: [] as any,
            files_program: [] as any,
            files_registration_form: [] as any,
            files_additional: [] as any,

            intoPost: false,
            date_key: 0,
            disabledDate: new Date("2024-01-15"),
        };
    },
    setup() {
        const resultsPerPage = 10;
        const router = useRouter();
        const route = useRoute();
        const addParticipantModalRef = ref<null | HTMLElement>(null) as any;
        const editorFr = "wysiwyg-fr";
        const editorEn = "wysiwyg-en";

        // init editor
        const optionsFr = {
            modules: {
                toolbar: {
                    container: "#toolbarFr",
                },
            },
            theme: "snow",
        };
        const optionsEn = {
            modules: {
                toolbar: {
                    container: "#toolbarEn",
                },
            },
            theme: "snow",
        };

        const backToList = () => {
            const routeData = router.resolve({
                name: "chapitres",
            });
            window.open(routeData.href, "_self");
        };

        const goToMember = (id: string) => {
            const routeData = router.resolve({
                name: "member-details",
                params: { member_id: id },
            });
            window.open(routeData.href, "_blank");
        };

        const chapitreSchema = object().shape({
            contact_en: string().required().label("English contact").nullable(),
            contact_fr: string().required().label("French contact").nullable(),
            location_en: string()
                .required()
                .label("English location")
                .nullable(),
            location_fr: string()
                .required()
                .label("French location")
                .nullable(),
            en_title: string().required().label("English title").nullable(),
            fr_title: string().required().label("French title").nullable(),
            livre_dor: string().required().label("Livre d'or").nullable(),
            ceremony_date: string()
                .required()
                .label("Induction date")
                .nullable(),
            end_date: string()
                .required()
                .label("Chapitre ending date")
                .nullable(),
            start_date: string()
                .required()
                .label("Chapitre starting date")
                .nullable(),
            master: string()
                .required()
                .label("Chapitre inducting officer")
                .nullable(),
            bailliage: string()
                .required()
                .label("Chapitre bailliage")
                .nullable(),
            type: string().required().label("Chapitre type").nullable(),
        });

        const participantSchema = object().shape({
            name: string().required().label("Participant name").nullable(),
            promotion: string().nullable(),
            is_omgd_induction: string().label("OMGD induction").nullable(),
            commandeur: string().label("Participant commandeur").nullable(),
            medal: string().label("Participant medal").nullable(),
        });

        const closeParticipantModal = () => {
            hideModal(addParticipantModalRef.value);
        };

        return {
            backToList,
            goToMember,
            closeParticipantModal,
            resultsPerPage,
            chapitreSchema,
            participantSchema,
            route,
            router,
            optionsFr,
            optionsEn,
            editorFr,
            editorEn,
            addParticipantModalRef,
        };
    },
    mounted() {
        this.title = document.querySelector(
            "#title-breadcrumbs h1"
        ) as HTMLElement;
        if (this.title) this.title.style.cssText = "display: none!important;";
        if (
            this.route.params.chapitre_id &&
            this.route.params.chapitre_id !== "add"
        ) {
            this.editing = true;
            this.getChapitre(this.route.params.chapitre_id);
            this.getParticipants(this.route.params.chapitre_id);
            setCurrentPageBreadcrumbs(
                "Chapitre #" + this.route.params.chapitre_id,
                [
                    {
                        link: "/chapitres/",
                        label: "Chapitres",
                    },
                ]
            );
        } else {
            this.loading = false;
            setCurrentPageBreadcrumbs("New Chapitre", [
                {
                    link: "/chapitres/",
                    label: "Chapitres",
                },
            ]);
        }

        this.frEditor = new Quill("#" + this.editorFr, this.optionsFr);
        this.enEditor = new Quill("#" + this.editorEn, this.optionsEn);

        var limit = 1500;

        this.frEditor.on("text-change", () => {
            var delta = this.frEditor.root.innerHTML;
            if (this.frEditor.getLength() > limit) {
                this.frEditor.deleteText(limit, this.frEditor.getLength());
            } else {
                this.chapitre.description_fr = delta;
            }
        });
        this.enEditor.on("text-change", () => {
            var delta = this.enEditor.root.innerHTML;
            if (this.enEditor.getLength() > limit) {
                this.enEditor.deleteText(limit, this.enEditor.getLength());
            } else {
                this.chapitre.description_en = delta;
            }
        });
        this.getFields();
        this.perm_pos = localStorage.getItem("perm_pos") as any;

        if (this.perm_pos !== "0") {
            this.getFrBailliage({
                id: localStorage.getItem("bailliage"),
                label_fr: localStorage.getItem("bailliage_label"),
            });
            this.chapitre.national_bailliage.id = parseInt(
                localStorage.getItem("bailliage") as any
            );
        }
    },
    beforeUnmount() {
        this.title.style.cssText = "display: flex !important;";
    },
    methods: {
        disableDate(date: any) {
            return date < this.disabledDate;
        },
        validate() {
            const button = document.getElementById("submitChapitre");
            if (button) button.click();
        },
        testSchema(values) {
            this.chapitreSchema.validate(values).catch((res: any) => {
                ElMessage({
                    type: "error",
                    message: res,
                });
                return 0;
            });
        },
        handleStartDate() {
            this.chapitre.end_date = this.chapitre.ceremony_date =
                this.chapitre.start_date;
            let date = moment(this.chapitre.start_date, "DD/MM/YYYY");

            let dayBefore = date.clone().subtract(1, "days");

            let formattedDayBefore = dayBefore.format("YYYY-MM-DD");

            this.disabledDate = new Date(formattedDayBefore.toString());
        },
        printDocument(scope: string, id?: number) {
            var payload = {
                is_post: this.intoPost,
            } as any;

            if (id) payload.members = [id];
            switch (scope) {
                case "induction_list":
                    chapitresApi
                        .getIntronisationList(
                            this.route.params.chapitre_id,
                            payload
                        )
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(
                                        res.data.intronisation_list_pdf_url,
                                        "_blank"
                                    )
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "livre_dor":
                    chapitresApi
                        .getLivreDor(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(
                                        res.data.golden_book_pdf_url,
                                        "_blank"
                                    )
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "pouvoirs":
                    chapitresApi
                        .getPouvoirs(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(res.data.pouvoir_pdf_url, "_blank")
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "cdr_diplomas":
                    payload.perimeter = "cdr";
                    chapitresApi
                        .getDiplomas(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(res.data.diploma_pdf_url, "_blank")
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "omgd_diplomas":
                    payload.perimeter = "omgd";
                    chapitresApi
                        .getDiplomas(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(res.data.diploma_pdf_url, "_blank")
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "cdr_nomination":
                    payload.type = "nomination_cdr";
                    chapitresApi
                        .getPDF(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(
                                        res.data.nomination_cdr_pdf_url,
                                        "_blank"
                                    )
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "omgd_nomination":
                    payload.type = "nomination_omgd";
                    chapitresApi
                        .getPDF(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(
                                        res.data.nomination_omgd_pdf_url,
                                        "_blank"
                                    )
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "promotions":
                    payload.type = "promotion";
                    chapitresApi
                        .getPDF(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(res.data.promotion_pdf_url, "_blank")
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
                case "cards":
                    chapitresApi
                        .getCards(this.route.params.chapitre_id, payload)
                        .then((res: any) => {
                            if (res.success) {
                                window
                                    .open(
                                        res.data.member_cards_zip_url,
                                        "_blank"
                                    )
                                    ?.focus();
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "Une erreur est survenue.",
                                });
                            }
                        });
                    break;
            }
        },
        downloadLivreDor(file_path: any) {
            if (file_path) window.open(file_path, "_blank")?.focus();
            else
                ElMessage({
                    type: "error",
                    message: "No file found.",
                });
        },
        downloadPDF(pdf: string, participant?: any) {
            console.log(participant);

            if (pdf === "single_diploma") {
                chapitresApi
                    .getDiplomas(this.route.params.chapitre_id, {
                        perimeter: participant.is_omgd_induction
                            ? "omgd"
                            : "cdr",
                        members: [participant.id],
                        is_post: participant.is_post ? true : false,
                    })
                    .then((res: any) => {
                        if (res.success) {
                            window
                                .open(res.data.diploma_pdf_url, "_blank")
                                ?.focus();
                        } else {
                            ElMessage({
                                type: "error",
                                message: "Une erreur est survenue.",
                            });
                        }
                        this.progressDialog = false;
                    });
            }
        },
        checkAll(scope: string) {
            var topCheckbox = document.getElementById(
                "topCheckbox-" + scope
            ) as any;
            var checkboxes = Array.from(
                document.getElementsByClassName(
                    "custom-checkbox-chapitres-" + scope
                ) as any
            );

            this.selectedParticipants = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.allParticipants.map((due: any) => {
                    this.selectedParticipants.push(due);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedParticipants = [];
            }
        },
        handleFile(e: any, scope: string) {
            var file = e.target.files[0];
            var filename = file.name;
            var formData = new FormData();
            formData.append("file", file);

            if (scope === "livre_dor" && this.files_livre_dor.length === 1) {
                ElMessage({
                    message: "You can only upload one livre d'Or",
                    type: "error",
                });
                return;
            }
            this["files_" + scope].push({ name: filename, data: file });
        },
        removeFile(scope: string, index: number) {
            this["files_" + scope].splice(index, 1);
        },
        handleSelectionChange(e: any) {
            this.selectedParticipants = e;
        },
        loadNextParticipants() {
            this.loadedParticipants = this.allParticipants.slice(
                0,
                this.loadedParticipants.length + 10
            );
        },
        manageChapitre(action: string, id: number) {
            var is_locked;

            if (action === "lock") is_locked = true;
            else if (action === "unlock") is_locked = false;

            chapitresApi
                .manageChapitres({ chapitres: [id], is_locked })
                .then(() => {
                    this.getChapitre(this.route.params.chapitre_id);
                    if (action === "lock") {
                        ElMessage({
                            message: "Chapitre locked",
                            type: "success",
                        });
                    } else if (action === "unlock") {
                        ElMessage({
                            message: "Chapitre unlocked",
                            type: "success",
                        });
                    }
                });
        },
        resetForm(id) {
            this.participant = {
                member: "",
                promotion: "",
                is_omgd_induction: false,
                commander: "",
                medal: "",
            };

            this.participantName = "";

            setTimeout(() => {
                (document.querySelector("#" + id) as any).click();
            }, 150);
        },
        getFrBailliage(bailliage: any) {
            this.chapitre.national_bailliage.label_fr = bailliage.label_fr
                ? bailliage.label_fr
                : "";
            this.chapitre.national_bailliage.particle_fr = bailliage.particle_fr
                ? bailliage.particle_fr
                : "";

            chapitresApi
                .getBailliFromBailliage(bailliage.id)
                .then((res: any) => {
                    this.chapitreBD = res.data.bailli_delegue
                        ? res.data.bailli_delegue.userprofile.firstname +
                          " " +
                          res.data.bailli_delegue.userprofile.lastname.toUpperCase()
                        : "-";
                    this.chapitre.master ? null : (this.chapitre.master = {});
                    this.chapitre.master.id = res.data.bailli_delegue
                        ? res.data.bailli_delegue.id
                        : "";
                    this.chapitreMaster =
                        this.chapitre.contact_fr =
                        this.chapitre.contact_en =
                            res.data.bailli_delegue
                                ? res.data.bailli_delegue.userprofile
                                      .firstname +
                                  " " +
                                  res.data.bailli_delegue.userprofile.lastname.toUpperCase()
                                : "";
                });
        },
        confirmDeletion(id: any) {
            ElMessageBox.confirm(
                "Are you sure to delete this participant?",
                "Warning",
                {
                    confirmButtonText: "OK",
                    cancelButtonText: "Cancel",
                    customClass: "custom-modal",
                    cancelButtonClass: "cancel-modal",
                }
            ).then(() => {
                this.deleteParticipants(id);
            });
        },
        getParticipants(id: any) {
            var payload;
            if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: 1,
                    presult: 9999,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: 1,
                    presult: 9999,
                };
            }

            if (this.participantSearch) {
                payload = { ...payload, query: this.participantSearch };
            }

            payload.is_post = false;

            chapitresApi.listParticipants(id, payload).then((response) => {
                this.preParticipants = response.data.participants_list;
                payload.is_post = true;
                chapitresApi.listParticipants(id, payload).then((response2) => {
                    this.postParticipants = response2.data.participants_list;
                });
                this.loading = false;
            });
        },
        manageParticipations(action: string, id?: number) {
            var participantsId = [] as any;
            var is_participating;

            if (id) participantsId.push(id);
            else
                participantsId = this.selectedParticipants.map(
                    (participant: any) => participant.id
                );

            if (action === "participate") is_participating = true;
            else if (action === "doesnt_participate") is_participating = false;

            chapitresApi
                .manageParticipations({
                    participants: participantsId,
                    is_participating,
                })
                .then(() => {
                    this.selectedParticipants = [];
                    this.getParticipants(this.route.params.chapitre_id);
                    ElMessage({
                        type: "success",
                        message: "Participants list updated.",
                    });
                });
        },
        addParticipant(e: any, id: any) {
            // If edition mode
            if (id) {
                if (this.editingParticipant) {
                    this.participant.member = this.participantMemberId;
                    this.participant.is_post = this.intoPost ? true : false;
                    chapitresApi
                        .updateParticipants(
                            this.participantId,
                            this.participant
                        )
                        .then((res: any) => {
                            this.getParticipants(this.route.params.chapitre_id);
                            this.closeParticipantModal();
                            this.clearParticipantModal();
                            this.resetForm("resetFormParticipant");
                            this.editingParticipant = false;
                        });
                    ElMessage({
                        type: "success",
                        message: "Participant updated!",
                    });
                } else {
                    this.participant.is_post = this.intoPost ? true : false;
                    chapitresApi
                        .addParticipant(this.route.params.chapitre_id, [
                            this.participant,
                        ])
                        .then((res) => {
                            if (res.success) {
                                this.getParticipants(
                                    this.route.params.chapitre_id
                                );
                                this.closeParticipantModal();
                                this.clearParticipantModal();
                                this.resetForm("resetFormParticipant");
                                ElMessage({
                                    type: "success",
                                    message: "Participant added!",
                                });
                            } else if (res.status === 409) {
                                ElMessage({
                                    type: "error",
                                    message:
                                        "This member is already in the list.",
                                });
                            }
                        });
                }
                // Else if we are adding a new chapitre
            } else {
                this.participantsTempList.push(this.tempParticipant);
                this.closeParticipantModal();
                this.clearParticipantModal();
            }
        },
        editParticipant(participant: any) {
            this.participantId = participant.id;
            this.participantMemberId = participant.member.id;
            this.editingParticipant = true;
            chapitresApi
                .listRemoteMembers(this.route.params.chapitre_id, {
                    query: participant.member.id.toString(),
                    is_post: this.intoPost ? true : false,
                })
                .then((res: any) => {
                    this.participantName =
                        res.data[0].userprofile.firstname +
                        " " +
                        res.data[0].userprofile.lastname;
                    this.disableParticipantName = true;
                    this.participantSelected = true;
                    this.participant.is_omgd_induction =
                        res.data[0].is_omgd_intronisable;
                    if (res.data[0].is_omgd_intronisable) {
                        this.intronisable = [
                            {
                                id: "",
                                label: "No",
                                value: false,
                            },
                            {
                                id: "",
                                label: "Yes",
                                value: true,
                            },
                        ];
                    } else {
                        this.intronisable = [
                            {
                                id: "",
                                label: "No",
                                value: false,
                            },
                        ];
                    }

                    if (res.data[0].intronisable_grades.length) {
                        this.participant.promotion = participant.promotion
                            ? participant.promotion.id
                            : "";
                        this.grades = res.data[0].intronisable_grades;
                        this.grades.unshift({
                            id: "",
                            label: "None",
                        });
                    } else {
                        this.grades = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                    }

                    if (res.data[0].intronisable_commanders.length) {
                        this.participant.commander = participant.commander
                            ? participant.commander.id
                            : "";
                        this.commandeurs = res.data[0].intronisable_commanders;
                        this.commandeurs.unshift({
                            id: "",
                            label: "None",
                        });
                    } else {
                        this.commandeurs = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                    }

                    if (res.data[0].intronisable_medals.length) {
                        this.participant.medal = participant.medal
                            ? participant.medal.id
                            : "";
                        this.medals = res.data[0].intronisable_medals;
                        this.medals.unshift({
                            id: "",
                            label: "None",
                        });
                    } else {
                        this.medals = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                    }
                });
        },
        deleteParticipants(id?: number) {
            var participantsToDelete = [] as any;
            if (id) {
                participantsToDelete.push(id);
            } else {
                this.selectedParticipants.map((participant: any) => {
                    participantsToDelete.push(participant.id);
                });
            }
            chapitresApi.deleteParticipants(participantsToDelete).then(() => {
                // var topCheckbox = document.getElementById("topCheckbox") as any;
                // topCheckbox.checked = false;
                // If we delete all the entries on the last page, we need to go back to the previous page
                // if (this.currentPage === this.totalPages && this.selectedParticipants.length === this.allParticipants.length) {
                //     this.currentPage = this.currentPage - 1;
                // }
                ElMessage({
                    type: "info",
                    message: "Participant(s) deleted.",
                });
                this.getParticipants(this.route.params.chapitre_id);
            });
        },
        clearParticipantModal(lock?: string) {
            if (lock) {
                this.participant.is_omgd_induction = false;
                this.intronisable = [
                    {
                        id: "",
                        label: "No",
                        value: false,
                    },
                ];
                this.grades = [
                    {
                        id: "",
                        label: "None",
                    },
                ];
                this.commandeurs = [
                    {
                        id: "",
                        label: "None",
                    },
                ];
                this.medals = [
                    {
                        id: "",
                        label: "None",
                    },
                ];
                this.participantSelected = false;
            } else {
                this.participant = {
                    member: "",
                    promotion: "",
                    is_omgd_induction: "",
                    commander: "",
                    medal: "",
                };

                this.tempParticipant = {
                    code: "",
                    firstname: "",
                    lastname: "",
                    grade: "",
                    induction: "",
                    is_participating: false,
                    payload: {
                        member: "",
                        promotion: "",
                        is_omgd_induction: "",
                        commander: "",
                        medal: "",
                    },
                };

                this.commandeurs = [
                    {
                        id: "",
                        label: "None",
                    },
                ];
                this.medals = [
                    {
                        id: "",
                        label: "None",
                    },
                ];

                this.participantName = "";
                this.requestedPromotion = "";
            }
        },
        handleChapitre() {
            if (
                this.files_livre_dor.length ||
                this.files_program.length ||
                this.files_registration_form.length ||
                this.files_additional.length
            ) {
                const uploadPromises = [] as any;

                if (this.files_livre_dor.length) {
                    uploadPromises.push(
                        this.tryUploadFiles(this.files_livre_dor, "livre_dor")
                    );
                }
                if (this.files_program.length) {
                    uploadPromises.push(
                        this.tryUploadFiles(this.files_program, "program")
                    );
                }
                if (this.files_registration_form.length) {
                    uploadPromises.push(
                        this.tryUploadFiles(
                            this.files_registration_form,
                            "registration_form"
                        )
                    );
                }
                if (this.files_additional.length) {
                    uploadPromises.push(
                        this.tryUploadFiles(this.files_additional, "additional")
                    );
                }

                Promise.all(uploadPromises)
                    .then(() => {
                        this.addChapitre();
                    })
                    .catch((error) => {
                        console.error("File upload failed:", error);
                    });
            } else {
                this.addChapitre();
            }
        },
        async tryUploadFiles(files, scope) {
            try {
                await this.uploadFiles(files, scope);
            } catch (error) {
                throw new Error(`File upload failed: ${error}`);
            }
        },
        uploadFiles(files, scope) {
            return new Promise((resolve, reject) => {
                var payload = new FormData();
                payload.append("type", scope);
                files.map((file: any) => {
                    payload.append("files", file.data);
                });

                axios
                    .post(
                        "/api/v1/chapitre/" +
                            this.route.params.chapitre_id +
                            "/upload-file/",
                        payload,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((response: any) => {
                        resolve(response);
                    })
                    .catch((error: any) => {
                        console.log(error);
                        reject();
                    });
            });
        },
        deleteFile(scope: string, name: string, index: number) {
            var payload = new FormData();
            payload.append("type", scope);
            payload.append("filename", name);

            axios
                .post(
                    "/api/v1/chapitre/" +
                        this.route.params.chapitre_id +
                        "/delete-file/",
                    payload,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then(() => {
                    this["files_" + scope].splice(index, 1);
                    ElMessage({
                        type: "success",
                        message: "File deleted.",
                    });
                });
        },
        addChapitre() {
            var payload = {
                type: this.chapitre.type.id,
                national_bailliage: this.chapitre.national_bailliage.id,
                master: this.chapitre.master.id,
                start_date: this.chapitre.start_date,
                end_date: this.chapitre.end_date,
                ceremony_date: this.chapitre.ceremony_date,
                is_livre_dor: this.chapitre.is_livre_dor,
                is_pouvoir: this.chapitre.is_pouvoir,
                is_published_online: this.chapitre.is_published_online,
                title_fr: this.chapitre.title_fr,
                title_en: this.chapitre.title_en,
                location_fr: this.chapitre.location_fr,
                location_en: this.chapitre.location_en,
                description_fr: this.chapitre.description_fr,
                description_en: this.chapitre.description_en,
                contact_fr: this.chapitre.contact_fr,
                contact_en: this.chapitre.contact_en,
                is_description_auto: this.chapitre.is_description_auto,
            };

            if (
                this.route.params.chapitre_id &&
                this.route.params.chapitre_id !== "add"
            ) {
                chapitresApi
                    .updateChapitre(this.route.params.chapitre_id, payload)
                    .then(() => {
                        ElMessage({
                            type: "success",
                            message: "Chapitre updated.",
                        });
                    });
            } else {
                chapitresApi.addChapitre(payload).then((res) => {
                    var participantsToAdd = [] as any;
                    this.participantsTempList.map((participant: any) => {
                        participantsToAdd.push(participant.payload);
                    });

                    this.router.push({
                        name: "chapitres",
                    });

                    // chapitresApi.addParticipant(res.data.created, participantsToAdd);
                    ElMessage({
                        type: "success",
                        message: "Chapitre added!",
                    });
                    this.editing = true;
                });
            }
        },

        getChapitre(id: any) {
            chapitresApi.getChapitre(id).then((response) => {
                this.chapitre = response.data;
                if (response.data.master) {
                    this.chapitreMasterId = response.data.master.id;
                    this.chapitreMaster =
                        response.data.master.userprofile.firstname +
                        " " +
                        response.data.master.userprofile.lastname;
                    this.chapitre.id = response.data.master.id;
                }

                for (const key in response.data.files) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            response.data.files,
                            key
                        )
                    ) {
                        const value = response.data.files[key];
                        if (Array.isArray(value) && value.length > 0) {
                            this["files_" + key] = [...value];
                        }
                    }
                }
                this.frEditor.container.firstChild.innerHTML =
                    response.data.description_fr;
                this.enEditor.container.firstChild.innerHTML =
                    response.data.description_en;
                this.chapitreBD = response.data.bailli_delegue;
                this.handleStartDate();

                // this.chapitre.start_date = moment(
                //     this.chapitre.start_date
                // ).format("YYYY-MM-DD");
            });
        },
        deteteChapitre() {
            chapitresApi
                .deleteChapitres([this.route.params.chapitre_id])
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: "Chapitre deleted.",
                    });
                    this.router.push({ name: "chapitres" });
                });
        },
        getFields() {
            membersApi.getBailliages().then((res) => {
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (bailliage.type === "National")
                        this.nBailliages.push(bailliage);
                });
            });

            chapitresApi
                .getChapitreTypes({ p: 1, presult: 9999 })
                .then((res) => {
                    this.chapitreTypes = res.data.object_list;
                });
        },
        loadRemoteMembers(query) {
            return new Promise((resolve, reject) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    chapitresApi
                        .listRemoteMembers(this.route.params.chapitre_id, {
                            query: query,
                            is_post: this.intoPost ? true : false,
                        })
                        .then((response) => {
                            if (response.success) {
                                response.data.map((remoteMember) => {
                                    this.remoteMembers.push({
                                        id: remoteMember.id,
                                        firstname:
                                            remoteMember.userprofile.firstname,
                                        lastname:
                                            remoteMember.userprofile.lastname,
                                        due: remoteMember.due
                                            ? remoteMember.due
                                            : null,
                                        code: remoteMember.code,
                                        is_omgd_intronisable:
                                            remoteMember.is_omgd_intronisable,
                                        grades: remoteMember.intronisable_grades,
                                        commanders:
                                            remoteMember.intronisable_commanders,
                                        medals: remoteMember.intronisable_medals,
                                    });
                                });
                                resolve("");
                            } else {
                                reject("");
                            }
                        });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);

                // Disable user select if participant due isn't up to date
                // SetTimeout to wait for the autocomplete opening animation
                setTimeout(() => {
                    // ul
                    var autocomplete = document.getElementsByClassName(
                        "el-autocomplete-suggestion__list"
                    )[0];
                    for (var i = 0; i < autocomplete.children.length; i++) {
                        // ul li
                        for (
                            var j = 0;
                            j < autocomplete.children[i].children.length;
                            j++
                        )
                            // ul li span
                            if (
                                autocomplete.children[i].children[
                                    j
                                ].className.match(
                                    "\\s*" + "text-danger" + "\\s*"
                                )
                            ) {
                                autocomplete.children[i].classList.add(
                                    "no-action"
                                );
                                break;
                            }
                    }
                }, 100);
            });
        },
        handleSelect(item: any, scope: string) {
            if (scope === "master") {
                if (this.chapitre.master) {
                    this.chapitre.master.id = item.id;
                } else {
                    this.chapitre.master = {
                        id: item.id,
                        code: "",
                        userprofile: {
                            id: "",
                            firstname: "",
                            lastname: "",
                        },
                    };
                }
                this.chapitreMaster = item.firstname + " " + item.lastname;
            } else if (scope === "participant") {
                this.participantSelected = true;
                if (this.route.params.chapitre_id) {
                    this.participant.member = item.id;
                    this.participantName = item.firstname + " " + item.lastname;
                    if (item.is_omgd_intronisable) {
                        this.intronisable = [
                            {
                                id: "",
                                label: "No",
                                value: false,
                            },
                            {
                                id: "",
                                label: "Yes",
                                value: true,
                            },
                        ];
                    } else {
                        this.intronisable = [
                            {
                                id: "",
                                label: "No",
                                value: false,
                            },
                        ];
                    }

                    if (item.grades.length) {
                        this.grades = item.grades;
                        this.grades.unshift({
                            id: "",
                            label: "None",
                        });
                        // this.grades = [
                        //     {
                        //         id: "",
                        //         label: "None",
                        //     },
                        //     {
                        //         id: item.promotion_id,
                        //         label: item.promotion_grade,
                        //     },
                        // ];
                    } else {
                        this.grades = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                    }

                    if (item.commanders.length) {
                        this.commandeurs = item.commanders;
                        this.commandeurs.unshift({
                            id: "",
                            label: "None",
                        });
                    } else {
                        this.commandeurs = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                    }

                    if (item.medals.length) {
                        this.medals = item.medals;
                        this.medals.unshift({
                            id: "",
                            label: "None",
                        });
                    } else {
                        this.medals = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                    }

                    this.participant.promotion = item.grades[0]
                        ? item.grades[0].id
                        : null;
                    this.participant.commander = "";
                    this.participant.medal = "";
                    this.participant.promotion = "";
                    // this.requestedPromotion = item.promotion_grade ? item.promotion_grade : "";
                    // this.participant.is_omgd_induction = item.is_omgd_intronisable ? true : false;
                } else {
                    this.participantName = item.firstname + " " + item.lastname;
                    this.tempParticipant.code = item.code;
                    this.tempParticipant.firstname = item.firstname;
                    this.tempParticipant.lastname = item.lastname;
                    this.tempParticipant.grade = item.promotion.label;
                    this.tempParticipant.induction = item.promotion.label;
                    this.requestedPromotion = item.promotion.label;

                    this.tempParticipant.payload.member = item.id;
                    this.tempParticipant.payload.promotion = item.promotion.id;
                    this.tempParticipant.payload.is_omgd_induction =
                        this.participant.is_omgd_induction;
                    this.tempParticipant.payload.commander = item.commandeur
                        ? item.commandeur.id
                        : null;
                    this.tempParticipant.payload.medal = item.medal
                        ? item.medal.id
                        : null;

                    if (item.commandeur.id)
                        this.commandeurs.push(item.commandeur);
                    else
                        this.commandeurs = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                    if (item.medal.id) this.medals.push(item.medal);
                    else
                        this.medals = [
                            {
                                id: "",
                                label: "None",
                            },
                        ];
                }
            }

            this.remoteMembers = [];
        },
        sortColumn(column: string, id: number) {
            console.log(column, id);

            var arrows = document.getElementById("chevrons-chapitre-" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            if (this.route.params.chapitre_id) {
                this.getParticipants(this.route.params.chapitre_id);
            }
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
    },
});
